:root {
  --margin: 24px 0;
  --margin-mini: 16px 0;
}

.projects_container {
  background-color: #f1f1f1;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.projects_article {
  background-color: var(--tertiary-color);
  min-height: 300px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.projects_article > h2 {
  font-size: 4rem;
  margin: var(--margin-mini);
  text-align: center;
}
.projects_article_container {
  display: flex;
  width: 100%;
  justify-content: space-around;
  padding:0 16px;
}
.project_photo_gallery {
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  min-width: 500px;
  width: 30%;
}
.project_youtube_container {
  min-width: 500px;
  width: 30%;
}
.project_image {
  object-fit: contain;
  min-height: 200px;
  min-width: 200px;
  height: 400px;
  width: 100%;
  -webkit-filter: drop-shadow(0px 0px 15px #222);
  filter: drop-shadow(0px 0px 15px #222);
}

.projects_article_item {
  min-width: 300px;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
}

.project_paragraph_title {
  font-size: 1.8rem;
  margin: 8px 0;
  text-align: justify;
}

.project_paragraph {
  font-size: 1.1rem;
  min-height: 100px;
  line-height: 1.4;
  font-family: Montserrat;
  margin: 16px 0;
}
.project_paragraph_addendum {
  text-align: justify;
  font-weight: 600;
  font-size: 1.1rem;
  min-height: 100px;
  line-height: 1.4;
  font-family: Montserrat;
  margin: 16px 0;
}
.project_list {
  width: 100%;
}
.projects_article_links {
  min-height: 50px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.link {
  color: #2d2d2d;
}
.link:visited {
  color: #2d2d2d;
}
.icon {
  width: 50px;
  height: 50px;
  opacity: 0.9;
}
.icon_small {
  width: 25px;
  height: 25px;
  opacity: 0.9;
}
.icon:hover {
  color: #ffffff;
  opacity: 1;
  -webkit-filter: drop-shadow(0px 0px 15px #222);
  filter: drop-shadow(0px 0px 15px #222);
  transition: color 0.3s ease-in-out;
}
.icon_small:hover {
  color: #ffffff;
  opacity: 1;
  -webkit-filter: drop-shadow(0px 0px 15px #222);
  filter: drop-shadow(0px 0px 15px #222);
  transition: color 0.3s ease-in-out;
}
.google_play_store:hover {
  color: #ffffff;
  opacity: 1;
  -webkit-filter: drop-shadow(0px 0px 15px #222);
  filter: drop-shadow(0px 0px 15px #222);
  transition: color 0.8s ease-in-out;
}
.projects_article_links {
  display: flex;
  width: 100%;
  justify-content: space-around;
}
@media (max-width: 1024px) {
  .projects_article_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 48px;
  }
  .projects_article_item {
    max-width: 90%;
    margin-bottom: 24px;
  }
  .project_photo_gallery {
    width: 90%;
  }
  .projects_article > h2 {
    font-size: 3.5rem;
  }
  .project_youtube_container {
    width: 90%;
  }
}

@media (max-width: 640px) {
  .project_photo_gallery {
    width: 100%;
    min-width: 300px;
  }
  .project_youtube_container {
    width: 100%;
    min-width: 300px;
  }
}
@media (max-width: 425px) {
  .project_paragraph_title {
    min-width: 0;
    margin: 12px 0;
  }
  .projects_article_item {
    min-width: 0;
  }
}
@media (max-width: 325px) {
  .project_photo_gallery {
    width: 100%;
    min-width: 264px;
  }
  .project_youtube_container {
    width: 100%;
    min-width: 264px;
  }
}
