.footer_container{background-color: red;max-width: 1440px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;  
   margin: 0 48px 24px 48px;width: calc(100% - 96px);
}
.footer {
  background-color: var(--primary-color);
  height: 300px;
  height: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
 }
.footer_title {
  width: 100%;
  margin-bottom: 12px;
}
.footer_title > h2 {
  height: 100px;
  font-size: clamp(36px, calc(30px + 2vw), 48px);
    z-index: 10;
  color: var(--primary-text-color);
  text-align: center;
}
.contact_container {
  min-height: 120px;
  width: 100%;
  display: flex;
}

.contact_form {
  background-color: var(--tertiary-color);
  width: 50%;
  min-height: 488px;
  font-size: 1.1rem;
  line-height: 1.4;
  font-family: Montserrat;
  margin: 16px 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.contact_form_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px 48px 0px 48px;
}

.contact_form_container > div {
  margin: 18px 0;
}

.contact_form_input {
  position: relative;
}
.contact_form_input > input {
  width: 100%;
  height: 40px;
  padding-left: 100px;
  border: 0;
  outline: 0;
}
.contact_form_input_label {
  position: absolute;
  left: 0.5em;
  top: 50%;
  transform: translateY(-50%);
  transition: top 400ms cubic-bezier(0.22, 1.16, 0.73, 0.98);
  cursor: pointer;
  user-select: none;
}

.contact_form_input > input:focus + .contact_form_input_label {
  top: -20px;
}
.contact_form_textarea {
  position: relative;
  min-height: 200px;
}
.contact_form_textarea > textarea {
  resize: none;
  width: 100%;
  height: 200px;
  padding-left: 120px;
  padding-top: 10px;
  border: 0;
  outline: 0;
}

.contact_form_textarea_label {
  position: absolute;
  left: 0.5em;
  top: 0%;
  transform: translateY(50%);
  transition: top 400ms cubic-bezier(0.22, 1.16, 0.73, 0.98);
  cursor: pointer;
  user-select: none;
}

.contact_form_textarea > textarea:focus + .contact_form_textarea_label {
  top: -40px;
}

.contact_form_input_span {
  position: absolute;
  height: 100%;
  width: 0px;
  background: #b8a1e1;
  transition: width 1000ms cubic-bezier(0.22, 1.16, 0.73, 0.98);
}
.contact_form_input:focus-within .contact_form_input_span {
  width: 76px;
}

.contact_form_textarea_span {
  position: absolute;
  height: 100%;
  width: 0px;
  background: #b8a1e1;
  transition: width 1000ms cubic-bezier(0.22, 1.16, 0.73, 0.98);
}

.contact_form_textarea:focus-within .contact_form_textarea_span {
  width: 76px;
}

.contact_form_textarea > textarea:focus,
.contact_form_input > input:focus {
  outline: none;
}

  .contact_media {
  background-color: var(--tertiary-color);
  width: 50%;
  min-height: 488px;
  font-size: 1.1rem;
  line-height: 1.4;
  font-family: Montserrat;
  margin: 16px 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.media_container {
  display: flex;
  flex-direction: column;
}

.socialLink {
  margin: 8px 0;
  list-style-type: none;
  font-weight: bold;
  animation: fade-in 1200ms ease-in;
  user-select: none;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

a {
  position: relative;
  color: #000;
  text-decoration: none;
}

.socialLink:hover {
  color: #000;
}

.socialLink::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #000;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.socialLink:hover::before {
  transform: scaleX(1);
}

.button_container {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 12px 0 24px 0;
}
.button {
  height: 60px;
  background-color: var(--primary-color);
  outline: none;
  width: 60%;
  border: none;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1.1rem;
  font-family: Montserrat;
  font-weight: 600;
  user-select: none;
  transition: filter 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.button:hover {
  filter: drop-shadow(0px 0px 15px #222);
}

.button:active {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  transform: scale(0.98);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
@media (max-width: 1024px) {
  .contact_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 48px;
  }
  .footer_title > h2 {
    display: flex;
    justify-content: center;
    align-content: center;
    margin: 0;
  }
  .contact_form_input {
    margin: 16px 0;
  }
  .contact_form {
    width: 100%;
  }
  .contact_media {
    width: 100%;
  }
}

@media (max-width: 850px) {
  .footer_container {
    width: calc(100% - 32px)}}

@media (max-width: 768px) {
  .contact_container {
    padding: 0 16px;
  }

  .contact_form {
    width: 100%;
    margin: 0px;
  }
  .contact_form_container {
    width: 100%;
  }
}
@media (max-width: 560px) {
  .contact_form_container {
    padding: 24px 16px;
  }
  .footer_title > h2 {
    font-size: 86px;
  }
}
/* //.contact_form_input > input {
  .contact_form_textarea > textarea {  padding: 48px; rgb(245 235 224 /${100}%)*/
